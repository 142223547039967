

import "./Categories.scss";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { baseUrl } from "../enviroment";
import Result from '../SearchResult/Result'
import NotFound from "../NotFound/NotFound";

const Categories = (props) => {
    const location = useLocation()
    const slug = location.pathname.substring(1);
    const [articles, setArticle] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [showResult, setShowResult] = useState(false);
    const [show404, setShow404] = useState(false);
    useEffect(() => {
        const getData = async () => {
            let res;
            if(props.allCategories) {
              res = await fetch(`${baseUrl}/article`);
            } else {
              res = await fetch(`${baseUrl}/article?slug=${slug}`);
            }
            const data = await res.json();
            if(!data.detail){
              setValues(data.results, data.count)
            } else {
              setShow404(true)
            }
        };
         getData();
    }, [slug]);

    const setValues = (results, count) => {
        setArticle(results);
        setPageCount(Math.ceil(count/8))
        setShowResult(true)
    }
    const pageClickHandler = async(e) => {
      let res;
      if(props.allCategories) {
        res = await fetch(`${baseUrl}/article/?page=${e.selected+1}`);
      } else {
        res = await fetch(`${baseUrl}/article/?page=${e.selected+1}&slug=${slug}`);
      }
      const data = await res.json();
      setValues(data.results, data.count)

    }

  return (
    <div>
       {showResult === true ?<Result articles={articles}
      count={pageCount} pageHandler = {pageClickHandler}
      />: ''}
       {show404 === true && showResult === false ?
              <NotFound/>
            : ''
            }

    </div>
     
  );
};

export default Categories;
