import styled from "styled-components";
import ReactPaginate from "react-paginate";

const Pagination = ({ pageClickHandler, pageCount }) => {
  return (
    <CustomPagination
      breakLabel="..."
      nextLabel=">"
      pageRangeDisplayed={5}
      pageCount={pageCount}
      previousLabel="<"
      onPageChange={(e) => pageClickHandler(e)}
    />
  );
};

const CustomPagination = styled(ReactPaginate).attrs({
  activeClassName: "active",
})`
  display: flex;
  outline: none;
  font-size: 1rem;
  list-style: none;
  margin: 10px 0px;
  margin-bottom: 30px;
  justify-content: center;
  li {
    padding: 1px 10px;
    margin: 0px 10px;
    outline: none;
  }
  li a {
    outline: none;
    color: black;
    cursor: pointer;
    text-decoration: none;
  }
  li.active {
    border-radius: 10px;
    box-shadow: 2px 2px 5px gray;
  }
  li.previous,
  li.next {
    font-weight: bold;
  }
`;

export default Pagination;
