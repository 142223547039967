

import CategoryList from "../CategoryList/CategoryList";
import "./About.scss";
import { Helmet } from "react-helmet";
const About = () => {
  return (
    <div className="about-content">
        <Helmet>
        <title>About - Dev Devout</title>
        <meta name="description" property="og:description" content="Dev Devout is an informational web development website. We cover all topics pertaining to web development and build our own custom web development applications to help developers, coders, and creatives around the world. "/>
        {/*<meta name="robots" content="noindex"/>*/}
        </Helmet>
        <h1>About Us</h1>
        <div className="grid-wrapper">   
            <div className="article-content footer" style={{lineHeight: "27px"}}>
                <p>
                    Dev Devout is an informational website for web development. We cover all topics pertaining to web development including articles and tutorials about programming languages, software-related products (some hardware), and build our own web development applications to help developers/coders around the world. To find out more information about what we do, please refer to our website.
                </p>
            </div>
            <CategoryList/>
        </div>
    </div>
  );
};

export default About;
