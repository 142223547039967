import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Navbar from "./Components/Navbar";

// css
import "./App.scss";
import "./scss/light.scss";
import "./scss/dark.scss";

// pages
import Main from "./pages/Main";
import GradientColorsApp from "./pages/GradientColors/GradientColorsApp";
import GradientBackgroundsApp from "./pages/GradientBackgrounds/GradientBackgroundsApp";
import Categories from "./pages/Categories/Categories";
import Footer from "./pages/Footer/Footer";
import About from "./pages/About/About";
import TermOfUse from "./pages/TermOfUse/TermOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Detail from "./pages/Detail/Detail";
import NotFound from "./pages/NotFound/NotFound";
import Search from "./pages/Search/Search";
import SiteMap from "./pages/Sitemap/Sitemap";

const App = () => {
  const [isBackgroundPageActive, setIsBackgroundPageActive] = useState(false);
  const [light_dark, setLightDark] = useState("lighttt");
  const [visible, setVisible] = useState(false)
  const handleLight = (light) => {
    setLightDark(light)
    localStorage.setItem('theme', light)
  }
  
  const toggleVisible = () => {
    if (window.pageYOffset > 400){
      setVisible(true)
    } else if (window.pageYOffset <= 400){
      setVisible(false)
    }
    
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  useEffect(()=>{
    if(localStorage.getItem('theme')){
      setLightDark(localStorage.getItem('theme'))
    }
  },[])

  // handleLight =(light)=> {
  //   setLightDark(light)
  // }

  return (
    <BrowserRouter>
      <div className="App">
        <div className={light_dark}>
          <div className="theme">
            <Navbar onSelectLight={handleLight} />
            <Routes>
              <Route path='*' exact={true} element={<NotFound/>} />
              <Route
                path=""
                element={
                  <Categories allCategories={true} />
                }
              />
              <Route
                exact={true}
                path="sitemap.xml"
                element={
                  <SiteMap/>
                }
              />
               <Route
                exact={true}
                path="search"
                element={
                  <Search/>
                }
              />
              <Route
                path=":slug/"
                element={
                  <Categories />
                }
              >
              </Route>
              <Route
                path=":slug/:slug/"
                element={
                  <Detail />
                }
              />
              <Route
                path="/gradients"
                element={
                  <Main setIsBackgroundPageActive={setIsBackgroundPageActive} />
                }
              />
              <Route
                path="gradients/gradient-colors/"
                element={
                  <GradientColorsApp
                    setIsBackgroundPageActive={setIsBackgroundPageActive}
                  />
                }
              />
              <Route
                path="gradient-colors/:category/"
                element={
                  <GradientColorsApp
                    setIsBackgroundPageActive={setIsBackgroundPageActive}
                  />
                }
              />
              <Route
                path="gradients/gradient-backgrounds/"
                element={
                  <GradientBackgroundsApp
                    setIsBackgroundPageActive={setIsBackgroundPageActive}
                  />
                }
              />
              <Route
                path="gradient-backgrounds/:category/"
                element={
                  <GradientBackgroundsApp
                    setIsBackgroundPageActive={setIsBackgroundPageActive}
                  />
                }
              />
              <Route
                path="about/"
                element={
                  <About/>
                }
              />
              <Route
                path="term-of-use/"
                element={
                  <TermOfUse/>
                }
              />
              <Route
                path="privacy-policy/"
                element={
                  <PrivacyPolicy/>
                }
              />
            </Routes>
            <button onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}}  id="button" className="show"></button>
            <Footer/>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
