import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { baseUrl } from "../../enviroment";
import { Helmet } from "react-helmet";

import { createLinearGradientCSS } from "./utils";

const LayoutScreen = ({
  setIsLayoutScreenActive,
  setLinearGradientCSS,
  setGradientColors,
  setIsOverflowVisible,
  setBackgroundColorIndex,
  APIColorsData,
  setAPIColorsData,
  allColors,
  setAllColors,
  setCategoryName,
  showFirst
}) => {
  // states
  const navigate = useNavigate();
  const location = useLocation();
  const [allCategoryColors, setAllCategoryColors] = useState([]);

  useEffect(() => {
    setCategoriesFromAPI();
    const Gradient_Background_Slug = getCategoryFromUrl();

    if (
      Gradient_Background_Slug === "" ||
      Gradient_Background_Slug === undefined
    ) {
      // set color list
      setGradientColorsFromAPI();
    } else {
      updateColorListCategoryWise({
        Gradient_Background_Slug: Gradient_Background_Slug
      });
    }
  }, []);

  // functions
  const setCategoriesFromAPI = async () => {
    const res = await fetch(`${baseUrl}/apiRoots/gradients/getCategory/`);
    const data = await res.json();
    setAllCategoryColors(data);
  };

  const setGradientColorsFromAPI = async () => {
    const res = await fetch(`${baseUrl}/apiRoots/gradients/all/`);
    const data = await res.json();

    const _allColors = [];
    for (let i = 0; i < data.length; i++) {
      data[i]["Gradient_Colors"] = data[i]["Gradient_Colors"].split(",");
      _allColors.push(data[i]);
    }
    setAPIColorsData(data);
    setAllColors(_allColors);
  };

  const getCategoryFromUrl = () => {
    const endpoint = location.pathname.split("/");
    if (endpoint[endpoint.length - 1] !== "") {
      if (endpoint[endpoint.length - 1] === "gradient-backgrounds") {
        return "";
      } else {
        return endpoint[endpoint.length - 1];
      }
    } else {
      if (endpoint[endpoint.length - 2] === "gradient-backgrounds") {
        return "";
      } else {
        return endpoint[endpoint.length - 2];
      }
    }
  };

  const updateBackground = (colorCombination, index) => {
    setGradientColors(colorCombination["Gradient_Colors"]);
    setBackgroundColorIndex(index);
    setLinearGradientCSS(
      createLinearGradientCSS(
        colorCombination["Gradient_Colors"],
        colorCombination["Angle_Value"]
      )
    );
    setIsOverflowVisible(false);
    setIsLayoutScreenActive(false);
  };

  const updateColorListCategoryWise = async (categoryItem, value=true) => {
    const slug = categoryItem.Gradient_Background_Slug;
    const res = await fetch(`${baseUrl}/apiRoots/gradients/all/${slug}/`);
    const data = await res.json();
    setCategoryName(`${data.length} ${data[0].Text} `)
    const _allColors = [];
    for (let i = 0; i < data.length; i++) {
      data[i]["Gradient_Colors"] = data[i]["Gradient_Colors"].split(",");
      _allColors.push(data[i]);
    }
    setAPIColorsData(data);
    setAllColors(_allColors);

    setGradientColors(_allColors[0]["Gradient_Colors"]);
    setBackgroundColorIndex(0);
    setLinearGradientCSS(
      createLinearGradientCSS(
        _allColors[0]["Gradient_Colors"],
        _allColors[0]["Angle_Value"]
      )
    );
    if(value===true && showFirst===true){
      updateBackground(data[0], 0)
    }
  };

  const setAllColorsBack = () => {
    navigate("/gradients/gradient-backgrounds/");
    // set all colors
    setCategoryName('')
    setAllColors(APIColorsData);
    setGradientColorsFromAPI()
  };

  const FilterColorBoxes = (categoryItem, index) => {
    const colorArr = categoryItem.Name.split(":");

    if (colorArr.length === 1) {
      return (
        <div key={index}>
          <Link
            to={`/gradient-backgrounds/${categoryItem.Gradient_Background_Slug}/`}
            onClick={() => updateColorListCategoryWise(categoryItem, false)}
          >
            <div
              className="color-box"
              style={{
                backgroundColor: colorArr[0],
                boxShadow: "2px 2px 5px rgb(56, 56, 56)",
              }}
            ></div>
          </Link>
          <p style={{ textAlign: "center" }}>{colorArr[0]}</p>
        </div>
      );
    } else if (colorArr.length === 3) {
      return (
        <div key={index}>
          <Link
            to={`/gradient-backgrounds/${categoryItem.Gradient_Background_Slug}/`}
            onClick={() => updateColorListCategoryWise(categoryItem, false)}
          >
            <div
              className="color-box"
              style={{
                background: `linear-gradient(${colorArr[2]}, ${colorArr[1]})`,
                boxShadow: "2px 2px 5px rgb(56, 56, 56)",
              }}
            ></div>
          </Link>
          <p style={{ textAlign: "center", width: "110px" }}>{colorArr[0]}</p>
        </div>
      );
    }
  };

  return (
    <StyledLayoutScreen>
      <StyledFilterDiv>
        <p style={{ marginLeft: "10px", marginBottom: "0px" }}>Gradient Background Filters</p>
        <StyledCategoryColorList className="gradient-background-scroll" style={{overflowX: 'scroll'}}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #a0a0a0",
            }}
            className="color-box"
            onClick={() => setAllColorsBack()}
          >
            <h2 style={{ color: "#a0a0a0" }}>All</h2>
          </div>
          {allCategoryColors &&
            allCategoryColors.map((categoryItem, index) =>
              FilterColorBoxes(categoryItem, index)
            )}
            
        </StyledCategoryColorList>
      </StyledFilterDiv>
      <StyledBackgroundList>
        <p style={{ marginLeft: "10px" }}>Gradient Backgrounds</p>
        <div className="gradient-background-list">
          {allColors.map((colorCombination, index) => {
            return (
              <StyledGradientBackgroundDiv
                key={index}
                _linearGradient={createLinearGradientCSS(
                  colorCombination["Gradient_Colors"],
                  colorCombination["Angle_Value"]
                )}
                onClick={() => updateBackground(colorCombination, index)}
              ></StyledGradientBackgroundDiv>
            );
          })}
        </div>
      </StyledBackgroundList>
      <button
        onClick={() => {
          setIsLayoutScreenActive(false);
          setIsOverflowVisible(false);
        }}
      >
        <i className="fas fa-times"></i>
      </button>
    </StyledLayoutScreen>
  );
};

const StyledGradientBackgroundDiv = styled.div`
  width: 25rem;
  height: 175px;
  margin: 10px auto;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.2s ease-in-out !important;
  background-image: ${(props) => props._linearGradient};
  &:hover {
    transform: translateY(-5%);
  }
`;

const StyledCategoryColorList = styled.div`
  display: flex;

  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #4a4d58;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px !important;
    background: #5e636e;
  }

  ::-webkit-scrollbar-corner {
    width: 6px !important;
    background: #5e636e;
  }

  @media only screen and (max-width: 768px) {
    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background: #4a4d58;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      width: 12px !important;
      background: #5e636e;
    }

    ::-webkit-scrollbar-corner {
      width: 12px !important;
      background: #5e636e;
    }
  }

  .color-box {
    width: 90px;
    height: 81.56px;
    margin: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 2px;
    transition: all 0.2s ease-in-out !important;
  }
  .color-box:hover {
    transform: translateY(-5%);
  }

  p {
    margin-bottom: 5px;
    font-size: 0.9rem;
  }
`;

const StyledFilterDiv = styled.div`
  // height: 178px;
  padding-top: 17px;
  // padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  border-radius: 10px;
  background-color: #4a4d58;

  @media only screen and (max-width: 768px) {
    height: 175px;
  }
`;

const StyledBackgroundList = styled.div`
  padding: 20px;
  color: white;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #4a4d58;

  .gradient-background-list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px;
  }

  @media only screen and (min-width: 2000px) {
    .gradient-background-list {
      justify-content: space-evenly;
    }

    .gradient-background-list > * {
      margin: 10px 5px;
    }
  }
`;

const StyledLayoutScreen = styled.main`
  width: 100vw;
  padding: 40px;
  position: relative;
  min-height: 100vh;
  background-color: #2c303a;

  button {
    top: 10px;
    right: 42px;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 18px;
    position: absolute;
    background-color: transparent;
  }

  @media only screen and (max-width: 1380px) {
    button {
      top: 0.3%;
    }
  }
  @media only screen and (max-width: 1150px) {
    button {
      right: 3%;
    }
  }
  @media only screen and (max-width: 850px) {
    button {
      top: 0.2%;
      right: 4%;
    }
  }
  @media only screen and (max-width: 550px) {
    button {
      top: 0.2%;
      right: 6%;
    }
  }
`;

export default LayoutScreen;
