import React,  { useRef, useLayoutEffect } from "react";
import { useState } from "react";
import "./Navbar.scss";
import "../scss/light.scss";
import "../scss/dark.scss";
import "../scss/body.scss";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
const Navbar = (props) => {
  const [isBackgroundPageActive, setIsBackgroundPageActive] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const location = useLocation()
  console.log(location)
  let navigate = useNavigate();
  const ref = useRef(null);
  const slug = location.pathname.substring(1);

  // Mobile view, hamburger menu open
  const openMenu = () => {
    document.getElementById("menuBars").style.display = "none";
    document.getElementById("closeCross").style.display = "block";
    document.getElementById("menus").style.display = "block";
  };

  const closeSearchPage=()=> {
    setShowSearch(!showSearch)
  }

  useLayoutEffect(() => {
    if(location.pathname === '/gradients/gradient-backgrounds/'){
      ref.current.style.setProperty("box-shadow", "0 1px 4px #00000038", "important");
    } else{
      ref.current.style.setProperty("box-shadow", "0 1px 4px #00000038");
    }
  }, [location]);

  const showSearchPage = () => {
    document.getElementsByTagName("footer")[0].style.display="none"
    if(location.pathname.startsWith('search')) {
      window.location.reload(false);
    }
    navigate('search', { replace: true, state: {show: true, path: location.pathname} });
  };

  // Mobile view, hamburger menu close
  const closeMenu = () => {
    document.getElementById("menuBars").style.display = "block";
    document.getElementById("closeCross").style.display = "none";
    document.getElementById("menus").style.display = "none";
  };

  // Will be used later for swapping the whole stylesheets
  // var setTheme = localStorage.getItem("theme");
  // const swapStyle = (sheet) => {
  //   document.getElementById("mystylesheet").href = sheet;
  //   localStorage.setItem("theme", sheet);
  // };
  // if (setTheme == null) {
  //   swapStyle(
  //     "https://dev-devout.nyc3.digitaloceanspaces.com/static/light.css"
  //   );
  // } else {
  //   swapStyle(setTheme);
  // }

  // const [light_dark, setLightDark] = useState("lighttt");

  return (
    <div className={`${slug.startsWith('gradient') ? "nav-container" : ""}`}>
      <nav ref={ref}  className="nav-desk navbar navbar-expand-lg navbar-light bg-light top-nav">
        {/* <a class="navbar-brand" href="{% url 'list' %}"> */}
        <a className="navbar-brand">
          <Link className="nav-link" to="/">
            <img
              src="/images/logo.png"
              alt="logo"
              className="logo"
              height="35"
              alt=""
            />
          </Link>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse mr-3" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/html">HTML</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/css">CSS</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/bootstrap">Bootstrap</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/gradients">Gradients</Link>
            </li>
          </ul>
          {/* <button className="btn my-2 my-sm-0 search" onclick="openSearch()"> */}
          <button className="btn my-2 my-sm-0 search" onClick={showSearchPage}>
            <i className="fal fa-search"></i>
          </button>
          {/* <!-- dark light modes--> */}
          <div className="modes">
            <button
              type="button"
              className="btn btn-light"
              // onClick={swapStyle("../../scss/dark.scss")}
              onClick={() => props.onSelectLight("darkkk")}
            >
              <i className="fad fa-sun"></i>
            </button>
            <button
              type="button"
              className="btn btn-dark"
              // onClick={swapStyle("../../scss/light.scss")}
              onClick={() => props.onSelectLight("lighttt")}
            >
              <i className="fad fa-moon"></i>
            </button>
          </div>
        </div>
      </nav>

      {/* mob */}
      <div className="main-container-mob">
        <nav className="navbar navbar-expand-lg navbar-light bg-light top-nav">
          {/* <a class="navbar-brand" href="{% url 'list' %}"> */}
          <a className="navbar-brand">
            <img src="/images/logo.png" height="35" alt="" />
          </a>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              {/* <button className="search" onclick="openSearch()"> */}
              <button className="search" onClick={showSearchPage}>
                <i className="fal fa-search"></i>
              </button>
            </li>
            <li className="nav-item">
              <div className="modes">
                <button
                  type="button"
                  className="btn btn-light"
                  // onclick="swapStyle('{% static 'dark.css' %}')"
                  onClick={() => props.onSelectLight("darkkk")}
                >
                  <i className="fad fa-sun"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-dark"
                  // onclick="swapStyle('{% static 'light.css' %}')"
                  onClick={() => props.onSelectLight("lighttt")}
                >
                  <i className="fad fa-moon"></i>
                </button>
              </div>
            </li>
            <li className="nav-item">
              <button className="list-menu" id="menuBars" onClick={openMenu}>
                <i className="fal fa-bars"></i>
              </button>
              <button className="list-menu" id="closeCross" onClick={closeMenu}>
                <i className="fal fa-window-close"></i>
              </button>
            </li>
          </ul>
        </nav>
        <div className="menus" id="menus">
          <div className="menu1">
            <a href="/html">HTML</a>
          </div>
          <div className="menu2">
            <a href="/css">CSS</a>
          </div>
          <div className="menu3">
            <a href="/bootstrap">Bootstrap</a>
          </div>
          <div className="menu3">
            <a href="/gradients">Gradients</a>
          </div>
        </div>
      </div>
      {/* mob */}
    </div>
  );
};

export default Navbar;
