import styled from "styled-components";

// utils
import { createLinearGradientCSS } from "../utils";

import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb,
} from "react-circular-input";

const AngleDegree = ({
  angleValue,
  setTempAngleDegree,
  setLinearGradientCSS,
  gradientColors,
  setIsStateUpdated,
}) => {
  // functions
  const updateAngleDegreeAndGradient = (value) => {
    setIsStateUpdated(true);
    setTempAngleDegree(value);
    setLinearGradientCSS(createLinearGradientCSS(gradientColors, value));
  };

  const manualInputHandler = (e) => {
    if (e.target.value === "") {
      e.target.value = 0;
    }
    updateAngleDegreeAndGradient(`${parseInt(e.target.value) % 360}`);
  };

  return (
    <AngleCircle>
      <CircularInput
        value={angleValue / 360}
        onChange={(value) => {
          if (Math.round(value * 360) === 360) {
            updateAngleDegreeAndGradient(0);
          } else {
            updateAngleDegreeAndGradient(value * 360);
          }
        }}
        className="circle-slider"
      >
        <CircularTrack strokeWidth={5} stroke="rgb(255, 255, 255)" />
        <CircularProgress strokeWidth={5} stroke="rgb(255, 255, 255)" />
        <CircularThumb
          r={10}
          fill="rgb(255, 255, 255)"
          style={{ cursor: "pointer" }}
        />
      </CircularInput>
      <p style={angleValue >= 100 ? { left: "40%" } : {}}>
        <StyledInput
          value={Math.round(angleValue)}
          width={`${Math.round(angleValue).toString().length * 16 + 8}px`}
          onChange={manualInputHandler}
          autoFocus={true}
        />
        <span>°</span>
      </p>
    </AngleCircle>
  );
};

const AngleCircle = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    color: white;
    font-size: 2rem;
    position: absolute;
    top: 40%;
    left: 42%;
  }
`;

const StyledInput = styled.input`
  outline: none;
  border: none;
  color: white;
  background: transparent;
  font-size: 2rem;
  width: ${(props) => props.width};
`;

export default AngleDegree;
