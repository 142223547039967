

import "./Search.scss";
import "../Categories/Categories.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, } from 'react-router-dom';
import { baseUrl } from "../enviroment";
import Result from "../SearchResult/Result";


const Search = (props) => {
  const [input, setInput] = useState(''); 
  const [result, showResult] = useState(false);
  const [articles, setArticle] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const {state} = useLocation()

  console.log(state)
  let navigate = useNavigate()

  useEffect(() => {
    if(state?.show) {
      showResult(false)
    }
  },[state])

  const closeSearch = () => {
    document.getElementsByTagName("footer")[0].style.display="block"
    if(input === '') {
      if(state?.path) {
        navigate(state.path)
      } else{
        navigate('/')
      }
    } else {
      showResult(true)
    }
  }

  const getSearch = async () => {
      document.getElementsByTagName("footer")[0].style.display="block"
      let res = await fetch(`${baseUrl}/article-search?query=${input}`);
      const data = await res.json();
      setValues(data.results, data.count)
      showResult(true)
  };
  function search(event) {
    event.preventDefault()
    if(input!== '') {
      getSearch()
    }
  }

  const pageClickHandler = async(e) => {
    let res = await fetch(`${baseUrl}/article-search/?page=${e.selected+1}&query=${input}`);
    const data = await res.json();
    setValues(data.results, data.count)
    showResult(true)

  }

  const setValues = (results,count) => {
    setArticle(results);
    setPageCount(Math.ceil(count/8))
  }

  return (
    <div>
      {result === false ? 
        <div id="myOverlay" class="overlay">
          <span class="closebtn" onClick={closeSearch} title="Close Overlay">×</span>
          <div class="overlay-content">
              <form onSubmit={search}>
                  <input value={input} onInput={e => setInput(e.target.value)} type="text" placeholder="Search.."/>
                  <button type="submit"><i class="fal fa-search"></i></button>
              </form>
          </div>
        </div>:
        <div>
          <div class="result">
            <p class="upper-line">Showing for results</p>
            <div class="textbox">
              <form onSubmit={search} action="/search/">
                <input name="query" value={input} onInput={e => setInput(e.target.value)} type="text" placeholder="Search.."/>
                <button type="submit" class="bttn"><i class="fal fa-search"></i></button>
              </form>
            </div>
          </div>
          <Result input={input} articles={articles}  pageHandler = {pageClickHandler}
          count={pageCount}/>
        </div>
      }
    </div>
   
  );
};

export default Search;
