import styled from "styled-components";

import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

import { MEDIUM_SCREEN_SIZE } from "./config";

const Controls = ({
  isAngleCircleActive,
  setIsAngleCircleActive,
  isDownloadModalOpen,
  setIsDownloadModalOpen,
  isCodeModalOpen,
  setIsCodeModalOpen,
  isLayoutScreenActive,
  setIsLayoutScreenActive,
  setShowFirst,
  isGradientHovered,
  setIsOverflowVisible,
  isStateUpdated,
  resetState,
}) => {
  const [isMediumScreen, setIsMediumScreen] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= MEDIUM_SCREEN_SIZE) {
      setIsMediumScreen(true);
    }
  }, []);

  const ControlsOnLargeScreens = () => {
    return (
      <>
        {!isAngleCircleActive &&
          !isDownloadModalOpen &&
          !isCodeModalOpen &&
          !isLayoutScreenActive &&
          isGradientHovered && (
            <>
              <button
                onClick={() => {
                  setIsLayoutScreenActive(true);
                  setIsOverflowVisible(true);
                  setShowFirst(false)
                }}
              >
                <Tooltip title="All Gradients" placement="right">
                  <i className="fas fa-bars"></i>
                </Tooltip>
              </button>
              <button onClick={() => setIsAngleCircleActive(true)}>
                <Tooltip title="Edit Angle" placement="right">
                  <i className="fas fa-circle-notch"></i>
                </Tooltip>
              </button>
              <button onClick={() => setIsCodeModalOpen(true)}>
                <Tooltip title="Hex/CSS Code" placement="right">
                  <i className="fas fa-code"></i>
                </Tooltip>
              </button>
              <button onClick={() => setIsDownloadModalOpen(true)}>
                <Tooltip title="Download Image" placement="right">
                  <i className="fas fa-download"></i>
                </Tooltip>
              </button>
              {isStateUpdated && (
                <button onClick={() => resetState()}>
                  <Tooltip title="Reset Gradient" placement="right">
                    <i className="fas fa-redo-alt"></i>
                  </Tooltip>
                </button>
              )}
            </>
          )}
      </>
    );
  };

  const ControlsOnMediumAndSmallScreens = () => {
    return (
      <>
        {!isAngleCircleActive &&
          !isDownloadModalOpen &&
          !isCodeModalOpen &&
          !isLayoutScreenActive && (
            <>
              <button
                onClick={() => {
                  setIsLayoutScreenActive(true);
                  setIsOverflowVisible(true);
                  setShowFirst(false)
                }}
              >
                <Tooltip title="All Gradients" placement="right">
                  <i className="fas fa-bars"></i>
                </Tooltip>
              </button>
              <button onClick={() => setIsAngleCircleActive(true)}>
                <Tooltip title="Edit Angle" placement="right">
                  <i className="fas fa-circle-notch"></i>
                </Tooltip>
              </button>
              <button onClick={() => setIsCodeModalOpen(true)}>
                <Tooltip title="Hex/CSS Code" placement="right">
                  <i className="fas fa-code"></i>
                </Tooltip>
              </button>
              <button onClick={() => setIsDownloadModalOpen(true)}>
                <Tooltip title="Download Image" placement="right">
                  <i className="fas fa-download"></i>
                </Tooltip>
              </button>
              {isStateUpdated && (
                <button onClick={() => resetState()}>
                  <Tooltip title="Reset Gradient" placement="right">
                    <i className="fas fa-redo-alt"></i>
                  </Tooltip>
                </button>
              )}
            </>
          )}
      </>
    );
  };

  return (
    <StyledControls isGradientHovered={isGradientHovered}>
      {isMediumScreen && <ControlsOnMediumAndSmallScreens />}
      {!isMediumScreen && <ControlsOnLargeScreens />}
      {isAngleCircleActive && (
        <button onClick={() => setIsAngleCircleActive(false)}>
          <i className="fas fa-times" style={{ fontSize: "20px" }}></i>
        </button>
      )}
    </StyledControls>
  );
};

const StyledControls = styled.div`
  top: 1%;
  right: 27px;
  gap: 1rem;
  display: flex;
  position: absolute;
  flex-direction: column;
  i {
    text-shadow: 3px 3px 6px rgba(114, 114, 114, 0.95);
  }
`;

export default Controls;
