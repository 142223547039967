import styled from "styled-components";

import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb,
} from "react-circular-input";

const AngleDegree = ({ angleValue, manualInputHandler, updateAngleValue }) => {
  return (
    <AngleCircle>
      <CircularInput
        value={angleValue / 360}
        onChange={(value) => updateAngleValue(value)}
        className="circle-slider"
      >
        <CircularTrack strokeWidth={5} stroke="rgb(255, 255, 255)" />
        <CircularProgress strokeWidth={5} stroke="rgb(255, 255, 255)" />
        <CircularThumb
          r={10}
          fill="rgb(255, 255, 255)"
          style={{
            cursor: "pointer",
          }}
        />
      </CircularInput>
      <p style={angleValue >= 100 ? { left: "40%" } : {}}>
        <StyledInput
          value={Math.round(angleValue)}
          width={`${Math.round(angleValue).toString().length * 16 + 8}px`}
          onChange={manualInputHandler}
          autoFocus={true}
        />
        <span>°</span>
      </p>
    </AngleCircle>
  );
};

const AngleCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    color: white;
    font-size: 2rem;
    position: absolute;
    top: 40%;
    left: 42%;

    input {
      font-size: inherit;
    }
  }
`;

const StyledInput = styled.input`
  outline: none;
  border: none;
  color: white;
  background: transparent;
  width: ${(props) => props.width};
`;

export default AngleDegree;
