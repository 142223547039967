
import './CategoryList.scss'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../enviroment";
const CategoryList = (props) => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const getData = async () => {
        const res = await fetch(`${baseUrl}/category`);
        const data = await res.json();
        setCategories(data);
    };
    getData();

  }, []);

  function setTitle(cat){
    if (props.getSubCategory) {
      props.getSubCategory(cat.Name)
    }
  }

  return (
    <div className="sidebar">
        <ul className="sub-categories">
            <p className="pt-md-0 pt-4">Categries</p>
            {categories.map((cat, index) => {
                return (
                  <li key={index} class="py-1">
                    <Link to={`/${cat.slug}`} onClick={() => setTitle(cat)}>{cat.Name}</Link>
                  </li>
                );
            })}
        </ul>
    </div>
  );
};

export default CategoryList;
