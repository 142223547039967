
import { useEffect, useState } from "react";
import { baseUrl } from "../enviroment";
import ReactHtmlParser from 'react-html-parser'

const SiteMap = () => {
  const [sitemap, setSitemap] = useState();
  useEffect(() => {
    const getData = async () => {
        fetch(`${baseUrl}/sitemap.xml`)
        .then((response) => response.text())
        .then(res=>{
          setSitemap(res);
        })
        .catch((error) => {
            console.log(error);
        });
    };
    getData();

  }, []);

  return (
    <div>
      <svg 
      xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
      />
      {/* {ReactHtmlParser(sitemap)} */}
    </div>
  );
};

export default SiteMap;
