// styles
import "./GradientColorsApp.scss";

// components
import PaginatedGradientBoxList from "./components/GradientBoxList/GradientBoxList";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import LazyLoad from 'react-lazyload';

const GradientColorsApp = ({ setIsBackgroundPageActive }) => {
  useEffect(() => {
    setIsBackgroundPageActive(false);
  }, []);

  return (
    <div className="GradientColors">
      <Helmet>
        <title>Gradient Colors - Dev Devout</title>
        <meta name="description" content="A curated collection of CSS gradients of which users can browse, edited, and copy the hex and CSS code of each gradient."/>
        {/*<meta name="robots" content="noindex"/>*/}
      </Helmet>
      <LazyLoad>
        <div className="wrapper">
          <PaginatedGradientBoxList itemsPerPage={9} />
        </div>
      </LazyLoad>
    </div>
  );
};

export default GradientColorsApp;
