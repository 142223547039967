

import "./Result.scss";
import { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import CategoryList from "../CategoryList/CategoryList";
import NotFound from "../NotFound/NotFound";
import Pagination from "../GradientColors/components/GradientBoxList/Pagination";
import { Helmet } from "react-helmet";

const Result = (props) => {

    const [articles, setArticle] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const {pathname} = useLocation();
    const [title, setTitle] = useState('');
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
      setArticle(props.articles)
      setPageCount(props.count)
      setNotFound(true)
      console.log(pathname)
    })

    function getSubCategory(title) {
        setTitle(title)
    }

    function getTitle(){
        if(pathname.substring(1).toUpperCase() === 'HTML' || pathname.substring(1).toUpperCase() === 'CSS') {
            return pathname.substring(1).toUpperCase();
        } else if (pathname.substring(1).toUpperCase() === 'BOOTSTRAP') {
            return 'Bootstrap';
        } else {
            return title;
        }
    }

    function dateFormat(date){
        let newDate = new Date(date)
        return newDate.toLocaleDateString("en-US", {month: 'short'}) + ' ' + newDate.toLocaleDateString("en-US", {day: 'numeric'}) + ", " +  newDate.getFullYear()
    }
    const detail = (article) => {
        // if(props.allCategories) {
        //     navigate(`${article.parent_category_slug}/${article.slug}`, { replace: true, state: article });
        // } else {
        //     navigate(`${article.slug}`, { replace: true, state: article });
        // }
    }

    return (
        <div className="main-content">
            {pathname !== '/'
            ? <Helmet>
                <title>{getTitle()} - Dev Devout</title>
                <meta name="description" property="og:description" content={`Free ${getTitle()} code examples, informational articles, and tutorials`}/>
                {pathname.substring(1).toUpperCase() !== 'HTML' & pathname.substring(1).toUpperCase() !== 'CSS' & pathname.substring(1).toUpperCase() !== 'BOOTSTRAP' ? <meta name="robots" content="noindex"/>:'' }
             </Helmet>: <Helmet>
                <title>Dev Devout</title>
                <meta name="description" property="og:description" content="Well organized easily understandable free informational web development code examples, tutorials, how-tos, articles, etc. pertaining to programing languages and software such as HTML, CSS, JavaScript, Python, Bootstrap, WordPress, and more."/>
            </Helmet> }

            
        <div className="main-col">
            {articles.map((article, index) => {
                return (
                    <div onClick={() => detail(article)} className="card post" id="large-card">
                        
                        <a href={`${article.parent_category_slug}/${article.slug}`} className="image-link"><img src={article.image} className="post-image"/></a>
                        <div className="card-body">
                            <a href={`${article.parent_category_slug}/${article.slug}`} className="post-text">
                            <h3 className="post-title">{article.title}</h3>
                            <p className="post-snippet">{article.pre_description}
                            </p>
                            {article.Published_on ?<p className="post-date">{dateFormat(article.Published_on)}</p>: ''}
                            </a>
                        </div>
                    </div>
                );
            })}
            {pageCount>1?  <Pagination
            pageClickHandler={props.pageHandler}
            pageCount={pageCount}
            />: ''}
            {/* {notFound === true && articles.length === 0 ? (props.input ? <div className="no-result">
              <h4 class="not-found">No results found for: <em >{props.input}</em></h4>
              <Link to='/' class="button btn-1"><span>Home</span></Link>
              <hr class="divider-m" />
              </div> : <NotFound/>)
            : ''
            } */}
        </div>
        <CategoryList  getSubCategory = {getSubCategory} />
    </div>
    )

 
};

export default Result;
