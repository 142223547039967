import styled from "styled-components";

const SideControls = ({
  isAngleCircleVisible,
  isStateUpdated,
  setIsAngleCircleVisible,
  resetState,
  copyCSS,
  isHovered,
}) => {
  return (
    <StyledDiv>
      {!isAngleCircleVisible && isHovered && (
        <button className="copy-css" onClick={() => copyCSS()}>
          <i className="far fa-copy"></i>
        </button>
      )}
      {!isAngleCircleVisible && isHovered && (
        <button
          className="angle-degree"
          onClick={() => setIsAngleCircleVisible(true)}
        >
          <i className="fas fa-circle-notch"></i>
        </button>
      )}
      {isAngleCircleVisible && (
        <button
          className="close-angle-degree"
          onClick={() => setIsAngleCircleVisible(false)}
        >
          <i className="fas fa-times"></i>
        </button>
      )}

      {isStateUpdated && !isAngleCircleVisible && isHovered && (
        <button className="reset" onClick={() => resetState()}>
          <i className="fas fa-redo-alt"></i>
        </button>
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  i {
    font-size: 16px;
  }
  .close-angle-degree,
  .copy-css {
    top: 15px;
    right: 15px;
    color: white;
    position: absolute;
    text-shadow: 3px 3px 6px rgba(114, 114, 114, 0.95);
  }

  .angle-degree {
    top: 45px;
    right: 15px;
    color: white;
    position: absolute;
    text-shadow: 3px 3px 5px rgba(114, 114, 114, 0.95);
  }

  .reset {
    top: 75px;
    right: 15px;
    color: white;
    position: absolute;
    text-shadow: 3px 3px 6px rgba(114, 114, 114, 0.95);
  }
`;

export default SideControls;
