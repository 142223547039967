import "./GradientBackgroundsApp.scss";

import { useEffect, useState } from "react";
import GradientBackground from "./components/GradientBackground";
import { Helmet } from "react-helmet";

const GradientBackgroundsApp = ({ setIsBackgroundPageActive }) => {
  const [isGradientHovered, setIsGradientHovered] = useState(false);
  const [isOverflowVisible, setIsOverflowVisible] = useState(false);

  useEffect(() => {
    setIsOverflowVisible(false);
    setIsBackgroundPageActive(true);
  }, []);

  return (
    <div className="GradientBackgrounds">
      <Helmet>
      <title>Gradient Backgrounds - Dev Devout</title>
      <meta name="description" content="A curated collection of CSS gradient backgrounds. Users can browse, edit, and copy the hex and CSS code as well as download images and wallpapers of each gradient background. "/>
      {/*<meta name="robots" content="noindex"/>*/}
      </Helmet>
      <GradientBackground
        angleValue={20}
        isGradientHovered={isGradientHovered}
        setIsGradientHovered={setIsGradientHovered}
        setIsOverflowVisible={setIsOverflowVisible}
        _gradientColors={["white", "white"]}
      />
    </div>
  );
};

export default GradientBackgroundsApp;
