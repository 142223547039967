import { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import Modal from "react-modal";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";

import Controls from "./Controls";
import AngleDegree from "./AngleDegree";
import LayoutScreen from "./LayoutScreen";
import { MEDIUM_SCREEN_SIZE } from "./config";
import { colorNameToHex } from "./utils";
import { createLinearGradientCSS, webkitLinearGradientLine } from "./utils";
import { baseUrl } from "../../enviroment";
import { Helmet } from "react-helmet";
import { height } from "@mui/system";

const GradientBackground = ({
  _gradientColors,
  angleValue,
  setIsOverflowVisible,
  isGradientHovered,
  setIsGradientHovered,
}) => {
  // states
  const [tempAngleValue, setTempAngleValue] = useState(parseInt(angleValue));
  const [gradientColors, setGradientColors] = useState(_gradientColors);
  const [isAngleCircleActive, setIsAngleCircleActive] = useState(false);
  const [linearGradientCSS, setLinearGradientCSS] = useState(
    createLinearGradientCSS(gradientColors, tempAngleValue)
  );
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
  const [isLayoutScreenActive, setIsLayoutScreenActive] = useState(false);
  const [showFirst, setShowFirst] = useState(true);
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [imageType, setImageType] = useState(null);
  const [imageSize, setImageSize] = useState(null);
  const [allColors, setAllColors] = useState([]);
  const [backgroundColorIndex, setBackgroundColorIndex] = useState(0);
  const [isMediumScreen, setIsMediumScreen] = useState(false);
  const [APIColorsData, setAPIColorsData] = useState({});
  const location = useLocation();
  const [categoryName, setCategoryName] = useState('');
  const [items, setItems] = useState([
    { value: "United States", id: 1 },
    { value: "Canada", id: 2 },
    { value: "Mexico", id: 3 },
    { value: "Japan", id: 4 }
  ]);
  const [showItems, setShowItems] = useState(false);
  const [selectedItem, setSelectedItem] = useState(items[0])
  const sizes= [
    {
      text: "Large",
      width: 3840,
      height: 2160
    },
    {
      text: "Medium",
      width: 1024,
      height: 600
    },
    {
      text: "Small",
      width: 640,
      height: 360 
    }
  ]

  const imageTypeoptions = [
    { value: 'png', label: 'PNG' },
    { value: 'jpg', label: 'JPG' },
  ];
  const selectStyles = {
    container: (base, state) => ({
      position: 'absolute',
      width: '500px',
      zIndex: 500,
      ...base
    })
  };

  // refs
  const gradientBackgroundDiv = useRef(null);

  // functions
  const downloadAsJpg = () => {
    setIsDownloadModalOpen(false);
    domtoimage
      .toJpeg(gradientBackgroundDiv.current, {
        quality: 1,
        width: sizes[imageSize-1].width,
        height: sizes[imageSize-1].height,
      })
      .then((blob) => saveAs(blob, "gradient.jpeg"));
  };

  const downloadAsPng = () => {
    setIsDownloadModalOpen(false);
    domtoimage
      .toPng(gradientBackgroundDiv.current, {
        quality: 1,
        width: sizes[imageSize-1].width,
        height: sizes[imageSize-1].height,
      })
      .then((blob) => saveAs(blob, "gradient.png"));
  };

  const downloadImage = () => {
    if (imageType !== null && imageSize!==null) {
      if (imageType.toLowerCase() === "png") {
        downloadAsPng();
      }
      if (imageType.toLowerCase() === "jpg") {
        downloadAsJpg();
      }
      setImageSize(null)
      setImageType(null)
    }
  };

  const updateAngleDegreeAndGradient = (value) => {
    setTempAngleValue(value);
    setLinearGradientCSS(
      createLinearGradientCSS(gradientColors, Math.round(value))
    );
  };

  const manualInputHandler = (e) => {
    setIsStateUpdated(true);
    if (e.target.value === "") {
      e.target.value = 0;
    }
    updateAngleDegreeAndGradient(`${parseInt(e.target.value) % 360}`);
  };

  const updateAngleValue = (value) => {
    setIsStateUpdated(true);
    if (Math.round(value * 360) === 360) {
      updateAngleDegreeAndGradient(0);
    } else {
      updateAngleDegreeAndGradient(value * 360);
    }
  };

  const updateLinearGradientCSS = (e, index) => {
    gradientColors[index] = e.target.value;
    setGradientColors([...gradientColors]);
    setLinearGradientCSS(
      createLinearGradientCSS([...gradientColors], Math.round(tempAngleValue))
    );
  };

  const resetState = () => {
    setTempAngleValue(allColors[backgroundColorIndex]["Angle_Value"]);
    setGradientColors(allColors[backgroundColorIndex]["Gradient_Colors"]);
    setLinearGradientCSS(
      createLinearGradientCSS(
        allColors[backgroundColorIndex]["Gradient_Colors"],
        allColors[backgroundColorIndex]["Angle_Value"]
      )
    );
    setIsStateUpdated(false);
  };

  const swipeLeft = () => {
    setIsStateUpdated(false);
    if (backgroundColorIndex === 0) {
      let _index = allColors.length - 1;
      setBackgroundColorIndex(_index);
      setTempAngleValue(allColors[_index]["Angle_Value"]);
      setGradientColors([...allColors[_index]["Gradient_Colors"]]);
      setLinearGradientCSS(
        createLinearGradientCSS(
          allColors[_index]["Gradient_Colors"],
          allColors[_index]["Angle_Value"]
        )
      );
    } else {
      let _index = backgroundColorIndex - 1;
      setBackgroundColorIndex(_index);
      setTempAngleValue(allColors[_index]["Angle_Value"]);
      setGradientColors([...allColors[_index]["Gradient_Colors"]]);
      setLinearGradientCSS(
        createLinearGradientCSS(
          allColors[_index]["Gradient_Colors"],
          allColors[_index]["Angle_Value"]
        )
      );
    }
  };

  const swipeRight = () => {
    setIsStateUpdated(false);
    if (backgroundColorIndex === allColors.length - 1) {
      let _index = 0;
      setBackgroundColorIndex(_index);
      setTempAngleValue(allColors[_index]["Angle_Value"]);
      setGradientColors([...allColors[_index]["Gradient_Colors"]]);
      setLinearGradientCSS(
        createLinearGradientCSS(
          [...allColors[_index]["Gradient_Colors"]],
          allColors[_index]["Angle_Value"]
        )
      );
    } else {
      let _index = backgroundColorIndex + 1;
      setBackgroundColorIndex(_index);
      setTempAngleValue(allColors[_index]["Angle_Value"]);
      setGradientColors([...allColors[_index]["Gradient_Colors"]]);
      setLinearGradientCSS(
        createLinearGradientCSS(
          [...allColors[_index]["Gradient_Colors"]],
          allColors[_index]["Angle_Value"]
        )
      );
    }
  };

  const hexCodeCopyHandler = (e) => {
    navigator.clipboard.writeText(e.target.textContent);
    NotificationManager.success("Copied", "", 1000);
  };

  const copyCSSCodeHandler = (e) => {
    const _str = e.target.textContent.toString();
    const _textArr = _str.split(";");
    navigator.clipboard.writeText(`${_textArr[0]};\n${_textArr[1]}`);
    NotificationManager.success("Copied", "", 1000);
  };

  useEffect(() => {
    if (window.innerWidth <= MEDIUM_SCREEN_SIZE) {
      setIsMediumScreen(true);
    }
    if (
      location.pathname !== "/gradients/gradient-backgrounds/" &&
      location.pathname !== "/gradients/gradient-backgrounds"
    ) {
      setIsLayoutScreenActive(true);
    } else {
      setGradientColorsFromAPI();
    }
  }, [location]);

  useEffect(() => {
    if (window.innerWidth <= MEDIUM_SCREEN_SIZE) {
      setIsMediumScreen(true);
    }
  });

  const setGradientColorsFromAPI = async () => {
    const res = await fetch(`${baseUrl}/apiRoots/gradients/all/`);
    const data = await res.json();

    const allColors = [];
    for (let i = 0; i < data.length; i++) {
      data[i]["Gradient_Colors"] = data[i]["Gradient_Colors"].split(",");
      allColors.push(data[i]);
    }
    setAPIColorsData(data);
    setAllColors([...allColors]);
    setLinearGradientCSS(
      createLinearGradientCSS(
        allColors[0]["Gradient_Colors"],
        allColors[0]["Angle_Value"]
      )
    );
    setGradientColors(allColors[0]["Gradient_Colors"]);
  };

  // components
  const ControlArrowsOnLargeScreens = () => {
    return (
      <>
        {isGradientHovered &&
          !isCodeModalOpen &&
          !isDownloadModalOpen &&
          !isAngleCircleActive && (
            <button className="right-control control" onClick={swipeRight}>
              <i
                className="fas fa-chevron-right"
                style={{ fontSize: "24px" }}
              ></i>
            </button>
          )}
        {isGradientHovered &&
          !isCodeModalOpen &&
          !isDownloadModalOpen &&
          !isAngleCircleActive && (
            <button className="left-control control" onClick={swipeLeft}>
              <i
                className="fas fa-chevron-left"
                style={{ fontSize: "24px" }}
              ></i>
            </button>
          )}
      </>
    );
  };

  const dropDown = () => {
    setShowItems(true)
    // this.setState(prevState => ({
    //   showItems: !prevState.showItems
    // }));
  };

  const selectItem = item => {
    setSelectedItem(item)
    setShowItems(false)
    // this.setState({
    //   selectedItem: item,
    //   showItems: false
    // });
  };

  const ControlArrowsOnMediumAndSmallScreens = () => {
    return (
      <>
        {!isCodeModalOpen && !isDownloadModalOpen && !isAngleCircleActive && (
          <button className="right-control control" onClick={swipeRight}>
            <i
              className="fas fa-chevron-right"
              style={{ fontSize: "24px" }}
            ></i>
          </button>
        )}
        {!isCodeModalOpen && !isDownloadModalOpen && !isAngleCircleActive && (
          <button className="left-control control" onClick={swipeLeft}>
            <i className="fas fa-chevron-left" style={{ fontSize: "24px" }}></i>
          </button>
        )}
      </>
    );
  };

  return (
    <>
      <Helmet>
      <title>{`${ categoryName }Gradient Backgrounds - Dev Devout` }</title>
      <meta name="description" content={`A curated collection of ${categoryName}CSS gradient backgrounds. Users can browse, edit, and copy the hex and CSS code as well as download images and wallpapers of each gradient background. `}/>
      </Helmet>
      <NotificationContainer />
      <StyledScreen style={isLayoutScreenActive ? { display: "none" } : {}}>
        <StyledBackgroundDiv
          _linearGradient={linearGradientCSS}
          _height={window.innerHeight - 58}
          ref={gradientBackgroundDiv}
          onMouseMove={() => setIsGradientHovered(true)}
          onMouseEnter={() => setIsGradientHovered(true)}
        ></StyledBackgroundDiv>
        <div
          onMouseMove={() => setIsGradientHovered(true)} 
          onMouseEnter={() => setIsGradientHovered(true)}
          onMouseOut={() => setIsGradientHovered(false)}
          style={{
            top: "0%",
            left: "20%",
            width: "60vw",
            height: "100vh",
            position: "absolute",
          }}
          className="for-hovering-effect"
        ></div>

        <Controls
          isAngleCircleActive={isAngleCircleActive}
          setIsAngleCircleActive={setIsAngleCircleActive}
          isDownloadModalOpen={isDownloadModalOpen}
          setIsDownloadModalOpen={setIsDownloadModalOpen}
          setShowFirst={setShowFirst}
          isCodeModalOpen={isCodeModalOpen}
          setIsCodeModalOpen={setIsCodeModalOpen}
          isLayoutScreenActive={isLayoutScreenActive}
          setIsLayoutScreenActive={setIsLayoutScreenActive}
          isGradientHovered={isGradientHovered}
          setIsOverflowVisible={setIsOverflowVisible}
          isStateUpdated={isStateUpdated}
          resetState={resetState}
        />

        {isAngleCircleActive && (
          <AngleDegree
            angleValue={tempAngleValue}
            manualInputHandler={manualInputHandler}
            updateAngleValue={updateAngleValue}
          />
        )}

        {/* Download Modal  */}
        <Modal
          isOpen={isDownloadModalOpen}
          ariaHideApp={false}
          style={
            isMediumScreen ? MEDIUM_DOWNLOAD_MODAL_STYLE : DOWNLOAD_MODAL_STYLE
          }
        >
          <button
            onClick={() => {
              setIsDownloadModalOpen(false);
              setImageType(null);
              setImageSize(null)
            }}
            className="donwload-close-btn"
            style={MODAL_CLOSE_BUTTON_STYLE}
          >
            <i className="fas fa-times"></i>
          </button>
          <div className="d-flex gradient-download-mobile overflow-hidden">
            <div className="gradient-download-box rounded-left px-3 py-4">
              <i class="d-flex justify-content-center image-size fas fa-image"></i>
              <p className="my-2">Image size:</p>
              <select
                name="test"
                className="selc w-100"
                style={
                  imageSize == null
                    ? {
                        outline: "none",
                        border: "0px",
                        
                      }
                    : {
                        outline: "none",
                        border: "0px",
                        // padding: "8px 5px 8px 5px",
                        // borderTopLeftRadius: "5px",
                        // borderBottomLeftRadius: "5px",
                      }
                }
                onChange={(e) => setImageSize(e.target.selectedIndex)}
              >
                <option
                  selected
                  disabled
                  style={{ color: "gray", display: "none" }}
                >
                  Choose an image size
                </option >
                {sizes.map(size=>{
                   return (<option  style={{padding: "10px"}} > {size.text} {size.width} x {size.height}</option>)
                })}
                {/* <option>3840 x 2160</option>
                <option>1024 x 600</option>
                <option>640 × 360</option> */}
               
              </select>
              {/* <div class="vr"></div> */}

            </div>
            <div className="ruler py-3">
              <div class="vr h-100"></div>
            </div>
            <div className="gradient-download-box px-3 py-4">
              <i class="d-flex justify-content-center image-size fas fa-file"></i>
              <p className="my-2">File type:</p>
              {/* <div style={{ margin: "16px", position: "relative" }}>
                <div className="select-box--box">
                  <div className="select-box--container">
                    <div className="select-box--selected-item">
                      {selectedItem.value}
                    </div>
                    <div className="select-box--arrow" onClick={dropDown}>
                      <span
                        className={`${
                          showItems
                            ? "select-box--arrow-up"
                            : "select-box--arrow-down"
                        }`}
                      ></span>
                    </div>
                    <div
                      style={{ display: showItems ? "block" : "none" }}
                      className={"select-box--items"}
                    >
                      {items.map(item => (
                        <div
                          key={item.id}
                          onClick={() => selectItem(item)}
                          className={selectedItem === item ? "selected" : ""}
                        >
                          {item.value}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div> */}
             

              <select
                name="test"
                className="selc w-100"
                style={
                  imageType == null
                    ? {
                        outline: "none",
                        border: "0px",
                      }
                    : {
                        outline: "none",
                        border: "0px",
                        // padding: "8px 5px 8px 5px",
                        // borderTopLeftRadius: "5px",
                        // borderBottomLeftRadius: "5px",
                      }
                }
                onChange={(e) => setImageType(e.target.value)}
              >
                <option
                  selected
                  disabled
                  style={{ color: "gray", display: "none" }}
                >
                  Choose a file type
                </option>
                <option>PNG</option>
                <option>JPG</option>
               
              </select>

            </div>
            {/* <div className="gradient-download-btn rounded-right p-3 d-flex justify-content-center"> */}
              <button
                class="gradient-download-btn rounded-right p-3 d-flex justify-content-center align-items-center download-btn"
                  style={{
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                    color: imageType && imageSize ? 'black': ''
                    // position: "absolute",
                    // borderTopRightRadius: "5px",
                    // padding: "10px 10px 8px 10px",
                    // borderBottomRightRadius: "5px",
                    // background: "rgba(255, 255, 255, 0.3) !important",
                  }}
                  disabled={!imageType || !imageSize}
                  // style={{ color: imageType && imageSize ? 'black': ''}}
                  onClick={downloadImage}
                >
                  Download
                </button>
            {/* </div> */}
            {/* <p align="center" style={{ transform: "scale(1.5)" }}>
            <select
                name="test"
                class="selc"
                style={
                  imageType == null
                    ? {
                        color: "gray",
                        outline: "none",
                        border: "0px",
                        padding: "8px 5px 8px 5px",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }
                    : {
                        outline: "none",
                        border: "0px",
                        padding: "8px 5px 8px 5px",
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                      }
                }
                onChange={(e) => setImageType(e.target.value)}
              >
                <option
                  selected
                  disabled
                  style={{ color: "gray", display: "none" }}
                >
                  Choose..
                </option>
                <option>PNG</option>
                <option>JPG</option>
              </select>
              <select
                name="test"
                class="selc"
                style={
                  imageType == null
                    ? {
                        color: "gray",
                        outline: "none",
                        border: "0px",
                        padding: "8px 5px 8px 5px",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }
                    : {
                        outline: "none",
                        border: "0px",
                        padding: "8px 5px 8px 5px",
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                      }
                }
                onChange={(e) => setImageType(e.target.value)}
              >
                <option
                  selected
                  disabled
                  style={{ color: "gray", display: "none" }}
                >
                  Choose..
                </option>
                <option>PNG</option>
                <option>JPG</option>
              </select>

              <button
              class="download-btn"
                style={{
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                  position: "absolute",
                  borderTopRightRadius: "5px",
                  padding: "10px 10px 8px 10px",
                  borderBottomRightRadius: "5px",
                  background: "rgba(255, 255, 255, 0.3)",
                }}
                disabled={imageType == null ? true : false}
                onClick={downloadImage}
              >
                Download
              </button>
            </p> */}
          </div>
        </Modal>

        {/* Code Modal */}
        <Modal
          isOpen={isCodeModalOpen}
          ariaHideApp={false}
          style={isMediumScreen ? MEDIUM_MODAL_STYLE : MODAL_STYLE}
        >
          <button
            onClick={() => setIsCodeModalOpen(false)}
            style={MODAL_CLOSE_BUTTON_STYLE_CODE}
          >
            <i className="fas fa-times"></i>
          </button>
          <div className="body" style={{ margin: "auto" }}>
            <div style={{ margin: "1rem 0rem" }}>
              <h3 style={{ margin: "5px 0px", fontSize: "1.1rem" }}>
                Hex Codes
              </h3>
              {gradientColors.map((gradientColor, index) => {
                return (
                  <span key={index}>
                    <input
                      style={{
                        width: "15px",
                        border: "none",
                        height: "15px",
                        padding: "0px",
                        outline: "none",
                        cursor: "pointer",
                        marginLeft: "0px",
                        marginRight: "5px",
                        backgroundColor: gradientColor,
                      }}
                      type="color"
                      value={colorNameToHex(gradientColor.trim())}
                      onChange={(e) => {
                        setIsStateUpdated(true);
                        updateLinearGradientCSS(e, index);
                      }}
                    />
                    <Tooltip title="copy" placement="top">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={hexCodeCopyHandler}
                      >
                        {gradientColor}
                      </span>
                    </Tooltip>
                    {index === gradientColors.length - 1 ? "" : ", "}
                  </span>
                );
              })}
            </div>
            <div>
              <h3 style={{ margin: "5px 0px", fontSize: "1.1rem" }}>
                CSS Code
              </h3>
              <Tooltip title="copy" placement="top">
                <div style={{ cursor: "pointer" }} onClick={copyCSSCodeHandler}>
                  background-color: {linearGradientCSS};
                  <br />
                  background-color:
                  {webkitLinearGradientLine(
                    gradientColors,
                    Math.round(tempAngleValue)
                  )}
                  ;
                </div>
              </Tooltip>
            </div>
          </div>
        </Modal>

        {!isMediumScreen && <ControlArrowsOnLargeScreens />}
        {isMediumScreen && <ControlArrowsOnMediumAndSmallScreens />}
      </StyledScreen>
      {isLayoutScreenActive && (
        <LayoutScreen
          showFirst={showFirst}
          setIsLayoutScreenActive={setIsLayoutScreenActive}
          setLinearGradientCSS={setLinearGradientCSS}
          setGradientColors={setGradientColors}
          setIsOverflowVisible={setIsOverflowVisible}
          setBackgroundColorIndex={setBackgroundColorIndex}
          APIColorsData={APIColorsData}
          setAPIColorsData={setAPIColorsData}
          allColors={allColors}
          setAllColors={setAllColors}
          setCategoryName={setCategoryName}
        />
      )}
    </>
  );
};

const StyledBackgroundDiv = styled.div`
  height: ${(props) => props._height}px;
  width: 100vw;
  position: relative;
  background-image: ${(props) => props._linearGradient};
`;

const StyledScreen = styled.div`
  position: relative;

  button {
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;

    i {
      color: white;
      font-size: 18px;
    }
  }

  .control {
    top: 45%;
    position: absolute;

    i {
      font-size: 2rem;
      text-shadow: 3px 3px 5px rgba(114, 114, 114, 0.95);
    }
  }
  .right-control {
    right: 1%;
  }
  .left-control {
    left: 1%;
  }

  .model {
    background-color: black;
  }
`;

// modal styles
const MODAL_STYLE = {
  overlay: {
    borderRadius: "10px",
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  content: {
    top: "50%",
    left: "50%",
    maxWidth: "600px",
    height: "200px",
    border: "none",
    display: "flex",
    borderRadius: "10px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255,255,255,0.3)",
  },
};

const MEDIUM_MODAL_STYLE = {
  overlay: {
    borderRadius: "10px",
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  content: {
    top: "50%",
    left: "50%",
    width: "90vw",
    border: "none",
    display: "flex",
    minHeight: "200px",
    borderRadius: "10px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255,255,255,0.3)",
  },
};

const DOWNLOAD_MODAL_STYLE = {
  overlay: {
    borderRadius: "10px",
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  content: {
    top: "50%",
    left: "50%",
    // width: "800px",
    width:"100%",
    height: "100%",
    // height: "200px",
    overflow: "hidden",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0,0,0,0)",
  },
};

const MEDIUM_DOWNLOAD_MODAL_STYLE = {
  overlay: {
    borderRadius: "10px",
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  content: {
    top: "50%",
    left: "50%",
    // width: "400px",
    // height: "400px",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0,0,0,0)",
  },
};

const MODAL_CLOSE_BUTTON_STYLE = {
  top: "7%",
  right: "25px",
  border: "none",
  color: "white",
  cursor: "pointer",
  fontSize: "20px",
  position: "absolute",
  backgroundColor: "rgba(0,0,0,0)",
};
const MODAL_CLOSE_BUTTON_STYLE_CODE = {
  top: "12px",
  right: "15px",
  border: "none",
  color: "white",
  cursor: "pointer",
  fontSize: "20px",
  position: "absolute",
  backgroundColor: "rgba(0,0,0,0)",
}

export default GradientBackground;
