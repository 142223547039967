import styled from "styled-components";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import GradientBox from "../GradientBox/GradientBox";
import { useNavigate, useLocation } from "react-router-dom";
import { baseUrl } from "../../../enviroment";
import { Helmet } from "react-helmet";
import Pagination from "./Pagination";
import PaginatedCategoryList from "./PaginatedCategoryList";

const PaginatedGrdientBoxList = ({ itemsPerPage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [APIColorsData, setAPIColorsData] = useState({});
  const [allCategoryColors, setAllCategoryColors] = useState([]);
  const [isCategoryListOpen, setIsCategoryListOpen] = useState(true);
  const [allGradientColorList, setAllGradientColorList] = useState([]);
  const [isCategoryItemSelected, setIsCategoryItemSelected] = useState(false);
  const [categoryName, setCategoryName] = useState('')

  const GradientBoxList = ({ currentItems }) => {
    return (
      <StyledGradientList>
        {currentItems.map((gradientCombination, index) => {
          return (
            <GradientBox
              gradientColors={gradientCombination["Gradient_Colors"]}
              angleDegree={gradientCombination["Angle_Value"]}
              key={index}
            />
          );
        })}
      </StyledGradientList>
    );
  };

  useEffect(() => {
    setCategoriesFromAPI();
    const categorySlug = getCategoryFromUrl();

    if (categorySlug === "" || categorySlug === undefined) {
      // set color list
      setGradientColorsWithPaginationFromAPI();
    } else {
      updateColorListCategoryWise({ categorySlug: categorySlug });
    }
  }, [itemOffset, itemsPerPage, location]);

  // functions
  const setCategoriesFromAPI = async () => {
    const res = await fetch(`${baseUrl}/apiRoots/gradients/getCategory/`);
    const data = await res.json();
    setAllCategoryColors(data);
  };

  const setGradientColorsWithPaginationFromAPI = async () => {
    const res = await fetch(`${baseUrl}/apiRoots/gradients/all/`);
    const data = await res.json();

    const allColors = [];
    for (let i = 0; i < data.length; i++) {
      data[i]["Gradient_Colors"] = data[i]["Gradient_Colors"].split(",");
      allColors.push(data[i]);
    }
    setAPIColorsData(data);
    setAllGradientColorList([...allColors]);

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(allColors.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(allColors.length / itemsPerPage));
  };

  const updateColorListCategoryWise = async (categoryItem) => {
    const slug = categoryItem.categorySlug;
    const res = await fetch(`${baseUrl}/apiRoots/gradients/all/${slug}/`);
    const data = await res.json();
    setCategoryName(`${data.length} ${data[0].Text} `)
    const allColors = [];
    for (let i = 0; i < data.length; i++) {
      data[i]["Gradient_Colors"] = data[i]["Gradient_Colors"].split(",");
      allColors.push(data[i]);
    }

    setIsCategoryItemSelected(true);
    setAPIColorsData(allColors);
    setAllGradientColorList(allColors);
  };

  const getCategoryFromUrl = () => {
    const endpoint = location.pathname.split("/");
    if (endpoint[endpoint.length - 1] !== "") {
      if (endpoint[endpoint.length - 1] === "gradient-colors") {
        return "";
      } else {
        return endpoint[endpoint.length - 1];
      }
    } else {
      if (endpoint[endpoint.length - 2] === "gradient-colors") {
        return "";
      } else {
        return endpoint[endpoint.length - 2];
      }
    }
  };

  const pageClickHandler = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % allGradientColorList.length;
    setItemOffset(newOffset);
  };

  const setAllColorsBack = () => {
    navigate("/gradients/gradient-colors/");
    setIsCategoryItemSelected(false);
    setAllGradientColorList(APIColorsData);

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(APIColorsData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(APIColorsData.length / itemsPerPage));
  };

  const FilterColorBoxes = (categoryItem, index) => {
    const colorArr = categoryItem.Name.split(":");

    if (colorArr.length === 1) {
      return (
        <div key={index}>
          <Link to={`/gradient-colors/${categoryItem.Gradient_Color_Slug}/`}>
            <div
              className="color-box"
              style={{
                backgroundColor: colorArr[0],
                // boxShadow: "2px 2px 5px rgb(114, 114, 114)",
              }}
            ></div>
          </Link>
          <p style={{ textAlign: "center" }}>{colorArr[0]}</p>
        </div>
      );
    } else if (colorArr.length === 3) {
      return (
        <div key={index}>
          <Link to={`/gradient-colors/${categoryItem.Gradient_Color_Slug}/`}>
            <div
              className="color-box"
              style={{
                background: `linear-gradient(${colorArr[2]}, ${colorArr[1]})`,
                // boxShadow: "2px 2px 5px rgb(114, 114, 114)",
              }}
            ></div>
          </Link>
          <p style={{ textAlign: "center" }}>{colorArr[0]}</p>
        </div>
      );
    }
  };

  return (
    <>
    <Helmet>
      <title>{`${ categoryName }Gradient Colors - Dev Devout` }</title>
      <meta name="description" content={`A curated collection of ${categoryName}CSS gradients of which users can browse, edited, and copy the hex and CSS code of each gradient.`}/>
    </Helmet>
    <GradientBoxListPage>
      <StyledFilterDiv style={isCategoryListOpen ? {} : { height: "20px" }}>
        <p className="gradient-dark-color"
          style={
            isCategoryListOpen
              ? { marginLeft: "10px", color: "#717171", fontSize: "20px" }
              : { visibility: "hidden" }
          }
        >
          Gradient Color Filters
        </p>
        <CloseButton
          onClick={() => setIsCategoryListOpen(!isCategoryListOpen)}
          style={isCategoryListOpen ? {} : { top: "35%", right: "0.5%" }}
        >
          {isCategoryListOpen && (
            <span style={{ margin: "0px 6px" }}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
          )}
          {!isCategoryListOpen && (
            <Tooltip title="Gradient Color Filters" placement="left">
              <span>
                <i className="fas fa-bars"></i>
              </span>
            </Tooltip>
          )}
        </CloseButton>
        {isCategoryListOpen && (
          <StyledCategoryColorList className="scroll-bg">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #a0a0a0",
              }}
              className="color-box"
              onClick={() => setAllColorsBack()}
            >
              <h2 style={{ color: "#a0a0a0", margin: "0px" }}>All</h2>
            </div>
            {allCategoryColors &&
              allCategoryColors.map((categoryItem, index) =>
                FilterColorBoxes(categoryItem, index)
              )}
          </StyledCategoryColorList>
        )}
      </StyledFilterDiv>
      {!isCategoryItemSelected && (
        <>
          <p className="gradient-dark-color" style={{ marginLeft: "10px", color: "#717171", fontSize: "20px" }}>
            Gradient Colors
          </p>
          <GradientBoxList currentItems={currentItems} />
          <Pagination
            pageClickHandler={pageClickHandler}
            pageCount={pageCount}
          />
        </>
      )}
      {isCategoryItemSelected && (
        <>
          <p style={{ marginLeft: "10px", color: "#717171", fontSize: "20px" }}>
            Gradient Colors
          </p>
          <PaginatedCategoryList
            itemsPerPage={itemsPerPage}
            gradientColors={allGradientColorList}
          />
        </>
      )}
    </GradientBoxListPage>
    </>
    
  );
};

const GradientBoxListPage = styled.div`
  margin: auto;
  width: 1068px;

  @media only screen and (max-width: 1150px) {
    & {
      width: 900px;
    }
  }

  @media only screen and (max-width: 950px) {
    & {
      width: 750px;
    }
  }

  @media only screen and (max-width: 800px) {
    & {
      width: 685px;
    }
  }

  @media only screen and (max-width: 740px) {
    & {
      width: 350px;
    }
  }
`;

const StyledGradientList = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  /* display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(3, 1fr); */

  @media only screen and (max-width: 1150px) {
    & {
      justify-content: space-evenly;
    }
  }
`;

const StyledFilterDiv = styled.div`
  height: 220px;
  overflow: hidden;
  padding: 20px 0px;
  border-radius: 10px;
  position: relative;
  box-sizing: content-box;
`;

const CloseButton = styled.button`
  top: 10%;
  right: 0%;
  position: absolute;
  i {
    color: white;
    font-size: 22px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
  }
`;

const StyledCategoryColorList = styled.div`
  display: flex;
  overflow-x: scroll;

  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 6px;
    height: 12px;
    background: #ffff;
  }

  ::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 5px;
    /* background: #e4e4e4; */
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-corner {
    width: 6px;
  }

  .color-box {
    width: 90px;
    margin: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    height: 81.56px;
    margin-bottom: 2px;
    border-radius: 10px;
    transition: all 0.2s ease-in-out !important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  }
  .color-box:hover {
    transform: translateY(-5%);
  }

  p {
    margin-bottom: 5px;
    font-size: 0.9rem;
  }
`;

export default PaginatedGrdientBoxList;
