import { useEffect, useState } from "react";
import { baseUrl } from "./enviroment";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Main.scss";

const Main = ({ setIsBackgroundPageActive }) => {
  const [categories, setCategories] = useState([]);
  const [totalGradients, setTotalGradients] = useState(null);

  useEffect(() => {
    setIsBackgroundPageActive(false);

    const getData = async () => {
      const res = await fetch(`${baseUrl}/apiRoots/gradients/sideBarCategories/`);
      const data = await res.json();
      setCategories(data);
    };

    const getTotalGradients = async () => {
      const res = await fetch(`${baseUrl}/apiRoots/gradients/totalGradients/`);
      const data = await res.json();
      setTotalGradients(data[0]["Total_Instances"]);
    };

    getData();
    getTotalGradients();
  }, []);

  return (
    <main className="wrapper">
      <Helmet>
      <title>Gradients - Dev Devout</title>
      <meta name="description" property="og:description" content="Well organized easily understandable informational Web development tutorials, code examples, how-tos, etc. of how to use programming languages such as HTML, CSS, JavaScript, Python, Bootstrap, Git, and more."/>

      </Helmet>
      <main className="main-content-container">
        <div className="main-links-container">
          <Link to="gradient-backgrounds/">
            <div className="container p-0">
              <div className="background bg-1"></div>
              <div className="content">
                <h1>Gradient Backgrounds</h1>
                <p>
                  Browse through our extensive collection of {totalGradients}{" "}
                  free gradient backgrounds. This CSS gradient background
                  tool contains various types of beautiful gradient backgrounds for
                  you to choose from and edit. As well as the ability to download an
                  image of each gradient background. Of which can be used as the
                  perfect gradient wallpaper or for other graphic design projects.
                  We have also included the hex and CSS code of each gradient
                  background for developers.{" "}
                  {/*Browse through our extensive collection of {totalGradients}{" "}*/}
                  {/*free gradient backgrounds. This includes various types*/}
                  {/*gradient backgrounds such as linear, radial, etc for you to*/}
                  {/*choose from. We have also included the hex and css code of*/}
                  {/*each gradient background. As well as the ability to download*/}
                  {/*an image of the gradient background of your choice.{" "}*/}
                </p>
              </div>
            </div>
          </Link>
          <Link to="gradient-colors/">
            <div className="container p-0">
              <div className="background bg-2"></div>
              <div className="content">
                <h1>Gradient Colors</h1>
                <p>
                  Explore our extensive collection of {totalGradients} free gradient colors.
                  Edit and choose from our beautiful selection
                  of CSS gradients via this gradient color tool. We have also
                  included the ability to copy the hex and CSS code of each gradient color you so wish.
                  {/*An agglomeration of free CSS navbars code examples from*/}
                  {/*Codepin, Youtube, and other online resources. Some of these*/}
                  {/*css navigation bars examples are responsive responsive*/}
                  {/*navigation menu, utilize flexbox, and...*/}
                </p>
              </div>
            </div>
          </Link>
        </div>
        <div className="sidebar-div">
          <div className="sidebar">
            <ul className="sub-categories">
              <p>Categries</p>
              {categories.map((cat, index) => {
                return (
                  <li key={index}>
                    <Link to={`${cat.Slug}`}>{cat.Name}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </main>
    </main>
  );
};

export default Main;
