

import { useEffect, useState } from "react";
import CategoryList from "../CategoryList/CategoryList";
import { useLocation, useNavigate } from 'react-router-dom';
import { baseUrl } from "../enviroment";
import LazyLoad from 'react-lazyload';
import "./Detail.scss";
import { Helmet } from "react-helmet";


const Detail = (props) => {
    const {state, pathname} = useLocation()
    let navigate = useNavigate();
    const [article, setArticle] = useState({});
    const [cardDetail, setcardDetail] = useState([]);
    const [play, setPlay] = useState(false);

    useEffect(() => {
        const getData = async (parent,slug) => {
            const res = await fetch(`${baseUrl}/article-detail?parent=${parent}&slug=${slug}`);
            const data = await res.json();
            if(data.length) {
              setArticle(data[0]);
              if(data[0].card_articles) {
                  setcardDetail(data[0].card_articles)
              }
            } else {
                navigate('/');
            }
        };
        if(!state) {
            const myArray = pathname.split("/");
            const parent = myArray [1];
            const slug = myArray [2];
            getData(parent, slug);
        } else {
            setArticle(state)
            if(state.card_articles) {
                setcardDetail(state.card_articles)
            }
        }
        setTimeout(()=>{
            const clip = document.querySelectorAll('.clip');
            for (let i = 0; i < clip.length; i++) {
                clip[i].addEventListener('mouseenter', function(e) {
                    clip[i].play()
                })
                clip[i].addEventListener('mouseout', function(e) {
                    clip[i].pause()
                })
            }
        }, 5000)

    }, []);

    function dateFormat(date){
        let newDate = new Date(date)
        var options = { month: 'short', weekday: 'short', year: 'numeric',day: 'numeric' };
        return newDate.toLocaleDateString("en-US", {month: 'short'}) + ' ' + newDate.toLocaleDateString("en-US", {day: 'numeric'}) + ", " +  newDate.getFullYear()
    }

    function getTitle() {
        
    }

    function isVideo(src) {
        const videos = ["mp4", "3gp", "ogg", 'mov', 'mkv', 'webm', 'flv']

        if (src !== 'nan') {
            const url = new URL(src)
            const extension = url.pathname.split(".")[1]
            if (videos.includes(extension)) {
                return true;
            }
        }

        return false;
    }
  return (
    <div className="main-artical-content ">
        <Helmet>
        {article.title ? <title>{ `${ article.title } - Dev Devout` }</title>: <title>Dev Devout</title> }
        
        <meta name="description" property="og:description" content= {article.meta_description} data-react-helmet="true"/>
        {/*<meta name="robots" content="noindex"/>*/}
        </Helmet>
        <div className="article-header">
            <h1 className="post-heading">{article.title}</h1>
            {article.Published_on?<p className="post-date">{dateFormat(article.Published_on)}</p>: ''}
            <p className="post-details"> {article.pre_description}</p>
        </div>
        <LazyLoad className="grid-wrapper">
            <div className="article-content" style={{lineHeight: '30px'}}>
                {(article.category === 'HTML' || article.category === 'html') ? 
                <div><img src={article.image} className="featured-image"/><br/><br/></div>: ''}
                {(article.category === 'HTML' || article.category === 'html') && (cardDetail.length === 0) ?
                <div dangerouslySetInnerHTML={{__html:article.description }} >
                </div>:
                <div class="text-part-card">
                    {cardDetail.map((data,index)=> {
                        return(
                            <div key={index} className="card post" id="large-card">
                                <div className="video-overlay">
                                   {data.video_src!== null && isVideo(data.video_src) ?<div className="video-overlay"> <video className="clip" loop muted playsInline><source src={data.video_src} type="video/mp4" /></video><div className="playpause px-2 py-1">Hover to play</div></div>:
                                   <div className="video-overlay"><img  className="clip-img" src={data.video_src}/></div>}
                                </div>
                                <div className="card-body">
                                    <div className="container-fluid desk-a code-card">
                                        <div className="row desk-b no-gutters">
                                            <div className="col-4 code-title">
                                                <h6 className="title" id="card-left">Title:</h6>
                                                <p className="further-details" id="card-left">{data.title}</p>
                                            </div>
                                            <div className="col-4 code-author">
                                                <h6 className="title" id="card-center">Author:</h6>
                                                <p className="further-details" id="card-center">{data.author}</p>
                                            </div>
                                            <div className="col-4 code-created-on">
                                                <h6 className="title" id="card-right">Created on:</h6>
                                                <p className="further-details" id="card-right">{data.created_on}</p>
                                            </div>
                                        </div>
                                        <div className="row desk-b no-gutters">
                                            <div className="col-4 code-made-with">
                                                <h6 className="title" id="card-left">Made with:</h6>
                                                <p className="further-details" id="card-left">{data.made_with}</p>
                                            </div>
        
                                            <div className="col-4 code-responsivity">
                                                <h6 className="title" id="card-center">Responsive:</h6>
        
                                                <p className="further-details" id="card-center">{data.responsive}</p>
                                            </div>
        
                                            <div className="col-4 code-dependencies">
                                                <h6 className="title" id="card-right">Dependencies:</h6>
        
                                                <p className="further-details" id="card-right">{data.dependencies}</p>
                                            </div>
                                        </div>
                                        <div className="row desk-b no-gutters">
                                            <div className="col-12 code-compatable-browsers">
                                                <h6 className="title" id="card-left">Compatable browsers:</h6>
                                                <p className="further-details" id="card-left">{data.compatible_browsers}</p>
                                            </div>
                                        </div>
                                        <div className="row desk-b no-gutters">
                                            <div className="col-12 code-description">
                                                <h6 className="title" id="card-left">Code description:</h6>
                                                <p className="further-details" id="card-left">{data.code_description}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wrapper" id="bgFade"><a className="button btn-1" href={data.code_demo_url} target="_blank">Code and Demo</a></div>
                                </div>
                            </div>
                        )

                    })}
                </div>
                }

            </div>
            <CategoryList/>
        </LazyLoad>
    </div>
    
  );
};

export default Detail;
