import { useState } from "react";

import styled from "styled-components";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import AngleDegree from "./AngleDegree";
import SideControls from "./SideControls";
import BottomControls from "./BottomControls";

// utils
import { createLinearGradientCSS } from "../utils";

const GradientBox = ({ gradientColors, angleDegree }) => {
  // states
  const [tempGradientColors, setTempGradientColors] = useState([
    ...gradientColors,
  ]);
  const [tempAngleDegree, setTempAngleDegree] = useState(angleDegree);
  const [linearGradientCSS, setLinearGradientCSS] = useState(
    createLinearGradientCSS(tempGradientColors, tempAngleDegree)
  );
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [isAngleCircleVisible, setIsAngleCircleVisible] = useState(false);
  const [colorPickerBoxes, setColorPickerBoxes] = useState(
    gradientColors.length
  );
  const [isHovered, setIsHovered] = useState(false);

  // funtions
  const updateGradientBackground = (e, index) => {
    setIsStateUpdated(true);
    tempGradientColors[index] = e.target.value;
    setLinearGradientCSS(
      createLinearGradientCSS(tempGradientColors, tempAngleDegree)
    );
  };

  const addGradientColor = () => {
    setIsStateUpdated(true);
    setColorPickerBoxes(colorPickerBoxes + 1);
    setTempGradientColors([...tempGradientColors, "#FFFFFF"]);
    setLinearGradientCSS(
      createLinearGradientCSS(
        [...tempGradientColors, "#FFFFFF"],
        tempAngleDegree
      )
    );
  };

  const copyCSS = () => {
    navigator.clipboard.writeText(`background-image: ${linearGradientCSS};`);
    NotificationManager.success("Copied", "", 1000);
  };

  const resetState = () => {
    setTempGradientColors([...gradientColors]);
    setTempAngleDegree(angleDegree);
    setColorPickerBoxes([...gradientColors].length);
    setLinearGradientCSS(
      createLinearGradientCSS([...gradientColors], angleDegree)
    );
    setIsStateUpdated(false);
  };

  return (
    <GradientDiv className="gradient-div" onMouseLeave={() => setIsHovered(false)}>
      <NotificationContainer />
      <GradientBackgroundDiv
        className="gradient-background"
        _linearGradientCSS={linearGradientCSS}
        onMouseEnter={() => setIsHovered(true)}
      ></GradientBackgroundDiv>
      <BottomControls
        tempGradientColors={tempGradientColors}
        updateGradientBackground={updateGradientBackground}
        addGradientColor={addGradientColor}
        colorPickerBoxes={colorPickerBoxes}
      />
      <SideControls
        isAngleCircleVisible={isAngleCircleVisible}
        isStateUpdated={isStateUpdated}
        setIsAngleCircleVisible={setIsAngleCircleVisible}
        resetState={resetState}
        copyCSS={copyCSS}
        isHovered={isHovered}
      />
      {isAngleCircleVisible && (
        <AngleDegree
          angleValue={tempAngleDegree}
          gradientColors={tempGradientColors}
          setTempAngleDegree={setTempAngleDegree}
          setLinearGradientCSS={setLinearGradientCSS}
          setIsStateUpdated={setIsStateUpdated}
        />
      )}
    </GradientDiv>
  );
};

const GradientDiv = styled.div`
  width: 320px;
  height: 344px;
  margin: 20px 10px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
`;

const GradientBackgroundDiv = styled.div`
  width: 320px;
  height: 290px;
  position: relative;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  background-image: ${(props) => props._linearGradientCSS};
`;

export default GradientBox;
