

import "./Footer.scss";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer>
        <span>©2021 | Dev Devout | All rights reserved &nbsp;&nbsp;&nbsp;&nbsp;
            {/* <Link to="/about"> */}
            <a href="/about" className="privacy-pages">About</a>
            {/* </Link> */}
            &nbsp;&nbsp;&nbsp;&nbsp; 
            {/* <Link to="/term-of-use"> */}
                <a href="/term-of-use" className="privacy-pages">Terms of Use</a> 
            {/* </Link> */}
            &nbsp;&nbsp;&nbsp;&nbsp; 
            {/* <Link to="/privacy-policy"> */}
            <a href="/privacy-policy" className="privacy-pages">Privacy and Cookie Policy</a> 
            {/* </Link> */}
            &nbsp;&nbsp;&nbsp;&nbsp; 
        </span>
    </footer>
  );
};

export default Footer;
