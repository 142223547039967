import { useState, useEffect } from "react";
import styled from "styled-components";
import GradientBox from "../GradientBox/GradientBox";

import Pagination from "./Pagination";

const PaginatedCategoryList = ({ itemsPerPage, gradientColors }) => {
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);

  const GradientBoxList = ({ currentItems }) => {
    return (
      <StyledGradientList>
        {currentItems.map((gradientCombination, index) => {
          return (
            <GradientBox
              key={index}
              angleDegree={gradientCombination["Angle_Value"]}
              gradientColors={gradientCombination["Gradient_Colors"]}
            />
          );
        })}
      </StyledGradientList>
    );
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(gradientColors.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(gradientColors.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, gradientColors]);

  // functions

  const pageClickHandler = (event) => {
    const newOffset = (event.selected * itemsPerPage) % gradientColors.length;
    setItemOffset(newOffset);
  };

  return (
    <GradientBoxListPage>
      <GradientBoxList currentItems={currentItems} />
      <Pagination pageClickHandler={pageClickHandler} pageCount={pageCount} />
    </GradientBoxListPage>
  );
};

const GradientBoxListPage = styled.div``;

const StyledGradientList = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */

  @media only screen and (max-width: 1150px) {
    & {
      justify-content: space-evenly;
    }
  }
`;

export default PaginatedCategoryList;
