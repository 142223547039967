

const NotFound = (props) => {
  return (
    <article class="e-404 mt-5 d-flex align-items-center flex-column">
        <div><h1>404 Page Not Found<sup>ERROR</sup></h1></div>
        <div><p>This page could not be found.</p></div>
        <div><a class="btn btn-blue" href="/">Back to Homepage</a></div>
    </article>
  );
};

export default NotFound;
