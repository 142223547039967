import styled from "styled-components";
import "rc-slider/assets/index.css";

import { colorNameToHex } from "../utils.js";

const MAX_COLORPICKER_BOXES = 7;

const BottomControls = ({
  tempGradientColors,
  updateGradientBackground,
  addGradientColor,
  colorPickerBoxes,
}) => {
  return (
    <StyledDiv>
      <div className="color-inputs">
        {tempGradientColors.map((gradientColor, index) => {
          return (
            <input
              type="color"
              onChange={(e) => updateGradientBackground(e, index)}
              value={colorNameToHex(gradientColor.trim())}
              key={index}
            />
          );
        })}
      </div>
      {colorPickerBoxes < MAX_COLORPICKER_BOXES && (
        <button
          className="add-gradient-background"
          onClick={() => addGradientColor()}
        >
          <i className="fas fa-plus"></i>
        </button>
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  input[type="color"] {
    width: 20px;
    height: 20px;
    padding: 0px;
    outline: none;
    margin: 0px 5px;
    cursor: pointer;
  }

  .color-inputs {
    left: 50%;
    display: flex;
    position: absolute;
    transform: translateX(-50%);
  }

  .add-gradient-background {
    margin: 5px;
    margin-left: auto;
    color: #a5a5a5;
    font-size: 20px;
  }
`;

export default BottomControls;
